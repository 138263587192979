import { default as _91id_93YkyvAuNsQyMeta } from "/Users/nficano/code/teachmehipaa/course/pages/[assessment]/[id].vue?macro=true";
import { default as indexXJmWeHtUIYMeta } from "/Users/nficano/code/teachmehipaa/course/pages/[assessment]/index.vue?macro=true";
import { default as reviewyvKBfIfKJnMeta } from "/Users/nficano/code/teachmehipaa/course/pages/[assessment]/review.vue?macro=true";
import { default as video_45_91id_93mbmgaLVEgcMeta } from "/Users/nficano/code/teachmehipaa/course/pages/[lesson]/video-[id].vue?macro=true";
import { default as certificated9ze5ELxlKMeta } from "/Users/nficano/code/teachmehipaa/course/pages/certificate.vue?macro=true";
import { default as entrySuFyrI9kwdMeta } from "/Users/nficano/code/teachmehipaa/course/pages/entry.vue?macro=true";
import { default as helloi6fQwM8sqqMeta } from "/Users/nficano/code/teachmehipaa/course/pages/hello.vue?macro=true";
import { default as indexNQtIpHGCbmMeta } from "/Users/nficano/code/teachmehipaa/course/pages/index.vue?macro=true";
import { default as _91token_93uGrdm31jLsMeta } from "/Users/nficano/code/teachmehipaa/course/pages/join/[token].vue?macro=true";
import { default as indexpJTJ7cjgRWMeta } from "/Users/nficano/code/teachmehipaa/course/pages/join/index.vue?macro=true";
import { default as signoutNrsNGtTecBMeta } from "/Users/nficano/code/teachmehipaa/course/pages/signout.vue?macro=true";
import { default as unauthorizedhk1udDvTjkMeta } from "/Users/nficano/code/teachmehipaa/course/pages/unauthorized.vue?macro=true";
export default [
  {
    name: "assessment-id",
    path: "/:assessment()/:id()",
    meta: _91id_93YkyvAuNsQyMeta || {},
    component: () => import("/Users/nficano/code/teachmehipaa/course/pages/[assessment]/[id].vue")
  },
  {
    name: "assessment",
    path: "/:assessment()",
    meta: indexXJmWeHtUIYMeta || {},
    component: () => import("/Users/nficano/code/teachmehipaa/course/pages/[assessment]/index.vue")
  },
  {
    name: "assessment-review",
    path: "/:assessment()/review",
    meta: reviewyvKBfIfKJnMeta || {},
    component: () => import("/Users/nficano/code/teachmehipaa/course/pages/[assessment]/review.vue")
  },
  {
    name: "lesson-video-id",
    path: "/:lesson()/video-:id()",
    meta: video_45_91id_93mbmgaLVEgcMeta || {},
    component: () => import("/Users/nficano/code/teachmehipaa/course/pages/[lesson]/video-[id].vue")
  },
  {
    name: "certificate",
    path: "/certificate",
    meta: certificated9ze5ELxlKMeta || {},
    component: () => import("/Users/nficano/code/teachmehipaa/course/pages/certificate.vue")
  },
  {
    name: "entry",
    path: "/entry",
    component: () => import("/Users/nficano/code/teachmehipaa/course/pages/entry.vue")
  },
  {
    name: "hello",
    path: "/hello",
    meta: helloi6fQwM8sqqMeta || {},
    component: () => import("/Users/nficano/code/teachmehipaa/course/pages/hello.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexNQtIpHGCbmMeta || {},
    component: () => import("/Users/nficano/code/teachmehipaa/course/pages/index.vue")
  },
  {
    name: "join-token",
    path: "/join/:token()",
    meta: _91token_93uGrdm31jLsMeta || {},
    component: () => import("/Users/nficano/code/teachmehipaa/course/pages/join/[token].vue")
  },
  {
    name: "join",
    path: "/join",
    meta: indexpJTJ7cjgRWMeta || {},
    component: () => import("/Users/nficano/code/teachmehipaa/course/pages/join/index.vue")
  },
  {
    name: "signout",
    path: "/signout",
    meta: signoutNrsNGtTecBMeta || {},
    component: () => import("/Users/nficano/code/teachmehipaa/course/pages/signout.vue")
  },
  {
    name: "unauthorized",
    path: "/unauthorized",
    component: () => import("/Users/nficano/code/teachmehipaa/course/pages/unauthorized.vue")
  }
]